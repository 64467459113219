<template>
  <ion-card>
    <ion-list>
      <ion-item>
        <ion-label>Paid</ion-label>
        <ion-note slot="end" color="contrast">
          <div class="flex items-center" style="">
            <img class="deso-xs" src="/assets/images/deso.svg" />
            <span>
                {{ utils.displayNanos(nftPaid) }}
            </span>
          </div>
        </ion-note>
      </ion-item>
      <ion-item>
        <ion-label>Collections</ion-label>
        <ion-note slot="end" color="contrast">
          {{ collectionsCount }}
        </ion-note>
      </ion-item>
      <ion-item lines="none">
        <ion-label>Number</ion-label>
        <ion-note slot="end" color="contrast">
            {{ nftCount }}
            </ion-note>
      </ion-item>
    </ion-list>
  </ion-card>
</template>

<script>

import { defineComponent } from "vue";
import { Utils } from "../services/utils";

import {
    IonCard,
    IonList,
    IonItem,
    IonLabel,
    IonNote,
} from "@ionic/vue";

export default defineComponent({
  components: {
    IonCard,
    IonList,
    IonItem,
    IonLabel,
    IonNote,
  },
    
  props: {
      filtering: null,

      totalNftCount: null,
      totalNftPaid: null,

      nftCount: null,
      collectionsCount: null,
      nftPaid: null,
  },

setup() {
    const utils = new Utils;

    return {
      utils: utils,
    }
  }
});
</script>