<template>
  <ion-page>
    <ion-header>

      <ion-toolbar>
        <div slot="start" class="ion-padding-start">
          <img class="h-6" src="/assets/images/wollo-brand-green.svg" />
        </div>
        <ion-buttons slot="end" class="ion-padding-end">
          <div v-if="exchangeRates.USDCentsPerDeSoExchangeRate" class="flex items-center text-sm">
            <img class="deso-xs" src="/assets/images/deso.svg" /> ≈
            {{ utils.displayFiat(exchangeRates.USDCentsPerDeSoExchangeRate) }} USD
          </div>
        </ion-buttons>

        <div class="flex justify-center">
        </div>
      </ion-toolbar>

    </ion-header>

    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
        <ion-refresher-content>
        </ion-refresher-content>
      </ion-refresher>

      <div v-if="loading">

        <ion-list>
          <ion-item lines="none">
            <ion-label>Loading</ion-label>
            <ion-spinner slot="start"></ion-spinner>
          </ion-item>
        </ion-list>

      </div>
      <div v-else>
        <div v-if="accountsCount ==0" class="ion-padding">
          <ion-text color="primary">
            <h1>Welcome to wollo</h1>    
          </ion-text>

          <ion-text>
            <p>Get started by adding your first account.</p>
          </ion-text>

          <ion-button href="/accounts">
            Manage accounts
          </ion-button>
        </div>
        <div v-else>

          <nft-stats 
            :filtering="filtering"
            :nftCount="nftCount" :collectionsCount="collectionsCount" :nftPaid="nftPaid" 
            />

          <ion-item lines="none">
            <div class="flex">
              <div class="flex items-center" @click="toggleShowFilter">
                <ion-icon color="dark" :icon="filterOutline"></ion-icon>
                <span class="ml-1">
                  Filter & Sort
                </span>
              </div>

              <div v-if="filtering" style="margin-left: 0.5em;">
                  <ion-text color="primary" slot="start" @click="resetFilters">
                    <small>Clear filters</small>
                  </ion-text>
              </div>

            </div>
            <ion-icon slot="end" color="dark" :icon="chevronUpOutline" v-if="showFilter == true" @click="toggleShowFilter"></ion-icon>
            <ion-icon slot="end" color="dark" :icon="chevronDownOutline" v-if="showFilter == false" @click="toggleShowFilter"></ion-icon>
          </ion-item>

          <ion-toolbar v-if="showFilter">
            <ion-select placeholder="Sort by" v-model="sortType">
              <ion-select-option value="purchased">Sort by Date</ion-select-option>
              <ion-select-option value="price">Sort by Price</ion-select-option>
            </ion-select>

            <ion-select v-if="collections.length > 1" placeholder="Filter collection" v-model="collectionFilter">
              <ion-select-option value="">All collections</ion-select-option>
              <ion-select-option
                v-for="item in collections"
                :value="item.username"
                :key="item.username"
                >{{ item.username }}</ion-select-option
              >
            </ion-select>

            <ion-select v-if="accounts.length > 1" placeholder="Filter accounts" v-model="accountFilter">
              <ion-select-option value="">All accounts</ion-select-option>
              <ion-select-option
                v-for="item in accounts"
                :value="item.username"
                :key="item.username"
                >{{ item.username }}</ion-select-option
              >
            </ion-select>

            <ion-searchbar v-model="searchQuery" debounce="100"></ion-searchbar>
          </ion-toolbar>
          <nft-grid :nfts="sortedNfts" :showHeader="true" />
        </div>
      </div>

    </ion-content>


    </ion-page>
</template>

<script>
import { ref, computed, defineComponent } from "vue";
import { useStore } from "vuex";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  // IonTitle,
  IonToolbar,

  IonRefresher,
  IonRefresherContent,

  // IonGrid, IonRow, IonCol,
} from "@ionic/vue";

import { 
  filterOutline,
  chevronDownOutline,
  chevronUpOutline
} from 'ionicons/icons';

import { Utils } from "../services/utils";
import NftGrid from "@/components/NftGrid.vue";
import NftStats from "@/components/NftStats.vue";

export default defineComponent({
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonText,
    // IonTitle,
    IonToolbar,
    
    NftGrid,
    NftStats,

    // IonGrid, IonRow, IonCol,
    IonRefresher,
    IonRefresherContent,

  },
  setup() {
    const utils = new Utils();
    const store = useStore();

    const loading = ref(true);
    
    const sortType = ref("purchased");
    const searchQuery = ref(null);
    const collectionFilter = ref(null);
    const accountFilter = ref(null);
    
    const showFilter = ref(false);

    store.dispatch("loadAccounts").then(function() {
      store.dispatch("loadNfts").then(function() {
        loading.value = false;
      });
    }) 

    const accounts = computed(function () {
      return store.getters.accounts;
    });

    const nfts = computed(function () {
      return store.getters.nfts;
    });

    const exchangeRates = computed(function () {
      return store.getters.exchangeRates;
    });

    const filtering = computed(function () {
      if (collectionFilter.value) {
        if (collectionFilter.value !== "") {
          return true;
        }
      }

      if (searchQuery.value) {
        if (searchQuery.value !== "") {
          return true;
        }
      }

      if (accountFilter.value) {
        if (accountFilter.value !== "") {
          return true;
        }
      }

      return false;
    });

    const sortedNfts = computed(function () {
      let list = nfts.value;

      if (collectionFilter.value) {
        if (collectionFilter.value !== "") {
          list = list.filter((item) => {
            return item.collection.username.includes(collectionFilter.value);
          });
        }
      }

      if (searchQuery.value) {
        if (searchQuery.value !== "") {
          const sq = searchQuery.value.toLowerCase();
          list = list.filter((item) => {
            return item.body.toLowerCase().includes(sq) || item.collection.username.toLowerCase().includes(sq);
            // return false;
          });
        }
      }

      if (accountFilter.value) {
        if (accountFilter.value !== "") {
          list = list.filter((item) => {
            return item.account.username.includes(accountFilter.value);
          });
        }
      }

      if (sortType.value == "purchased") {
        list = list.sort((a, b) => b.timestampNanos - a.timestampNanos);
      } else if (sortType.value == "collection") {
        list = list.sort((a, b) =>
          a.collection.username.localeCompare(b.collection.username)
        );
      } else if (sortType.value == "price") {
        list = list.sort((a, b) => b.paidNanos - a.paidNanos);
      }

      return list;
    });

    const nftCount = computed(function () {
      if (store.getters.nfts) {
        return sortedNfts.value.length;
      } else {
        return null;
      }
    });

    const totalNftCount = computed(function () {
      if (store.getters.nfts) {
        return nfts.value.length;
      } else {
        return null;
      }
    });

    const nftPaid = computed(function () {
      if (store.getters.nfts) {
        let nanos = 0;
        sortedNfts.value.map(function (nft) {
          nanos = nanos + nft.paidNanos;
        });
        return nanos;
      } else {
        return null;
      }
    });

    const collections = computed(function () {
      let list = [];
      if (nfts.value) {
        const collectionNames = [];

        for (const element of nfts.value) {
          if (element.collection && element.collection.username) {
            if (!collectionNames.includes(element.collection.username)) {
              list.push(element.collection);
              collectionNames.push(element.collection.username);
            }
          }
        }
        list = list.sort((a, b) => a.username.localeCompare(b.username));
      }
      return list;
    });

    const accountsCount = computed(function() {
      return store.getters.accounts.length;
    });

    const collectionsCount = computed(function() {
      let count = 0;

      if (sortedNfts.value) {
        const list = [];
        const collectionNames = [];

        for (const element of sortedNfts.value) {
          if (element.collection && element.collection.username) {
            if (!collectionNames.includes(element.collection.username)) {
              list.push(element.collection);
              collectionNames.push(element.collection.username);
            }
          }
        }
        count = list.length;
      }

      return count;
    });

    function displayNanos(number) {
      return new Intl.NumberFormat().format(number / 1e9);
    }

    function toggleShowFilter() {
      showFilter.value = !showFilter.value;
    }

    function resetFilters () {
      searchQuery.value = null;
      collectionFilter.value = null;
      accountFilter.value = null;
    }

    function refresh(event) {
      loading.value = true;
      store.dispatch("loadAccounts").then(function() {
        store.dispatch("loadNfts").then(function() {
          loading.value = false;
          event.target.complete();
        });
      }) 
    }

    return {
      utils: utils,
      loading: loading,

      filtering: filtering,
      searchQuery: searchQuery,
      sortType: sortType,
      collectionFilter: collectionFilter,
      accountFilter: accountFilter,

      accountsCount: accountsCount,
      collectionsCount: collectionsCount,
      nftCount: nftCount,
      nftPaid: nftPaid,

      totalNftCount: totalNftCount,

      collections: collections,
      accounts: accounts,

      exchangeRates: exchangeRates,

      nfts: nfts,
      sortedNfts: sortedNfts,
    
      displayNanos: displayNanos,
      resetFilters: resetFilters,

      refresh: refresh,

      filterOutline,
      chevronDownOutline,
      chevronUpOutline,
      showFilter: showFilter,
      toggleShowFilter: toggleShowFilter,
    };
  },
});
</script>
